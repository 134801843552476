
import React, { useState, useEffect } from 'react';
import './App.css';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ReactHlsPlayer from 'react-hls-player';
import ReactPlayer from 'react-player'
import axios from 'axios'
import MaterialTable from "@material-table/core";
import TextField from '@mui/material/TextField';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

/*
https://cevallos.ddns.net/
https://cevallos.ddns.net/api/
https://cevallos.ddns.net/stream/2.m3u8
https://cevallos.ddns.net/cevallos/record_2022-05-22-23.55.15.mp4
*/


/*
const urlApi = "http://192.168.0.3:3001/api/"
const urlStream = "http://192.168.0.3:6001/2.m3u8"
const urlVideos = "http://192.168.0.3:6001/cevallos/"
*/

const urlApi = "https://cevallos.ddns.net/api/"
const urlStream = "https://cevallos.ddns.net/stream/2.m3u8"
//const urlStream = 'http://186.139.105.87:6001/stream/2.m3u8'
const urlVideos = "https://cevallos.ddns.net/cevallos/"


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

function App() {

  const [data, setData] = useState(null)
  const [login, setLogin] = useState(null)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('l') === '291289cevallos') {
      setLogin(true)
    }
  }, [])


  useEffect(() => {
    axios.get(urlApi)
      .then(res => {
        var d = []
        res.data.forEach(element => {
          //console.log(element)
          if (element !== '') {
            let datetime = element.replace('record_', '').replace('.mp4', '').split('-')
            d.push({
              name: element,
              date: datetime[2] + '-' + datetime[1] + '-' + datetime[0],
              hour: datetime[3],
              datetime: element.replace('record_', '').replace('.mp4', '')
            })
          }
        });
        /*
        d.sort((a, b) => {
          return a.name - b.name;
        })*/
        //d.reverse()
        //console.log(d)
        d.shift()
        //console.log(d)
        setData(d);
      })

  }, [])


  if (!login) {
    return (
      <Box className="App" sx={{ flexGrow: 1 }}>
        <Container maxWidth="md" >
          <Box p={2} pt={8}>
            <Paper>
              <Box p={8}>
                <TextField id="standard-basic"
                  label="Ingrse contraseña"
                  type="password"
                  variant="standard"
                  error={error}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (localStorage.getItem('l') === '291289cevallos') {
                        setLogin(true)
                      } else {
                        setError(true)
                        setTimeout(() => {
                          setError(false)
                        }, 2000)
                      }
                    }
                  }
                  }
                  onChange={(e) => {
                    localStorage.setItem('l', e.target.value)
                  }
                  } />
                <br />
                <br />
                <Button
                  onClick={() => {
                    if (localStorage.getItem('l') === '291289cevallos') {
                      setLogin(true)
                    } else {
                      setError(true)
                      setTimeout(() => {
                        setError(false)
                      }, 2000)
                    }
                  }}
                  variant={"outlined"}
                >Ingresar</Button>
                <br />
                <br />

              </Box>
            </Paper>
          </Box>
        </Container>
      </Box>
    )
  }

  return (
    <Box className="App" sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>

          <Typography variant="h6" component="div"
            sx={{ flexGrow: 1 }}
          >
            Cámara - Cevallos
          </Typography>
          <Button color="inherit" onClick={() => {
            setLogin(null)
            localStorage.setItem('l', '')
          }}>salir</Button>
        </Toolbar>
      </AppBar>
      <Container maxWidth="md" >
        <Box p={0} mt={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}
            //md={7}
            >
              <Item>
                <Typography variant="h6" component="div">
                  CAM 1 - Live
                </Typography>

                <ReactHlsPlayer
                  src={urlStream}
                  autoPlay={false}
                  controls={true}
                  width="100%"
                  height="auto"
                  hlsConfig={{
                    maxLoadingDelay: 4,
                    minAutoBitrate: 0,
                    lowLatencyMode: true,
                  }}
                />
              </Item>
            </Grid>
            <Grid item xs={12}
            //md={5}
            >

              {data &&
                <Box pb={2} style={{ textAlign: 'right' }}>
                  <Button
                    style={{ display: 'none' }}
                    onClick={(e) => {
                      e.preventDefault()
                      return false
                      /*
                      const l = axios.get('http://192.168.0.3:3001/api/')
                        .then(res => {
                          var d = []
                          res.data.forEach(element => {
                            //console.log(element)
                            if (element !== '') {
                              let datetime = element.replace('record_', '').replace('.mp4', '').split('-')
                              d.push({
                                name: element,
                                date: datetime[2] + '-' + datetime[1] + '-' + datetime[0],
                                hour: datetime[3],
                                datetime: element.replace('record_', '').replace('.mp4', '')
                              })
                            }
                          });
                          setData(d.reverse());

                        })
                      return false
                      */
                    }}
                  > Actualizar videos</Button >
                </Box >
              }

              {data &&
                <MaterialTable
                  title='Videos - archivos'
                  data={data}
                  /*
                  data={query =>
                    new Promise((resolve, reject) => {
                      axios.get(urlApi)
                        .then(res => {
                          var d = []
                          res.data.forEach(element => {
                            //console.log(element)
                            if (element !== '') {
                              let datetime = element.replace('record_', '').replace('.mp4', '').split('-')
                              d.push({
                                name: element,
                                date: datetime[2] + '-' + datetime[1] + '-' + datetime[0],
                                hour: datetime[3],
                                datetime: element.replace('record_', '').replace('.mp4', '')
                              })
                            }
                          });
                          
                          resolve({
                            data: d.reverse(),
                            page: 1,
                            totalCount: d.length
                          })
                
                        })
                        .catch(e => console.log(e))
                    })
                  }*/
                  columns={[
                    {
                      title: "Video",
                      field: "name",
                      //width: 'auto',
                      //hidden:false
                    },
                    {
                      title: "Fecha",
                      field: "date",
                      //width: 100
                    },
                    {
                      title: "Hora",
                      field: "hour",
                      //width: 50
                    }
                  ]}
                  options={{
                    pageSize: 25,
                    pageSizeOptions: [25, 50, 75, 100, 200, 300, 500],
                    actionsColumnIndex: -1,
                    emptyRowsWhenPaging: false,
                  }}
                  actions={[
                    {
                      icon: () => <FileDownloadIcon />,
                      tooltip: 'Descargar video',
                      onClick: (event, rowData) => window.open(urlVideos + rowData.name, '_blank')
                    }
                  ]}
                  localization={{
                    pagination: {
                      labelDisplayedRows: '{from}-{to} de {count}',
                      labelRowsSelect: 'videos'
                    },
                    toolbar: {
                      nRowsSelected: '{0} registros seleccionados',
                      searchPlaceholder: 'Buscar...',
                      searchTooltip: 'Buscar'
                    },
                    header: {
                      actions: ''
                    },
                    body: {
                      emptyDataSourceMessage: 'No hay videos',
                      filterRow: {
                        filterTooltip: 'Filtrar'
                      }
                    }
                  }}
                  detailPanel={[
                    {
                      icon: () => <PlayCircleOutlineIcon />,
                      tooltip: 'Reproducir video',
                      width: 40,
                      render: (rowData) => {
                        return (
                          <>
                            {
                              //rowData.rowData.name
                            }
                            <ReactPlayer
                              url={urlVideos + rowData.rowData.name}
                              width='100%'
                              height='100%'
                              controls={true}
                            />
                          </>
                        )
                      }
                    }
                  ]
                  }
                />
              }
            </Grid >
          </Grid >
        </Box >
      </Container >
    </Box >
  );
}

export default App;
